"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _splitbutton = require('../split-button');
var _typography = require('../typography');
var _dialog = require('../dialog');
var _dialogcontent = require('../dialogcontent');
var _iconbutton = require('../iconbutton');
var _icons = require('../../icons');
var _button = require('../button');
var _reactintl = require('react-intl');
var _theme = require('../../theme');
var _foregroundpaper = require('../foreground-paper');
var _containerbox = require('../container-box');
const StyledDialogHeader = _styledcomponents2.default.call(void 0, _containerbox.ContainerBox).attrs({
  justifyContent: "space-between",
  fullWidth: true,
  alignItems: "center"
})``;
const StyledDialogContent = _styledcomponents2.default.call(void 0, _dialogcontent.DialogContent)`
  ${(_ref) => {
  let {
    theme: {
      space
    },
    withTitle
  } = _ref;
  return `
    display: flex;
    align-items: ${withTitle ? "stretch" : "center"};
    justify-content: center;
    padding: 0px;
    ${withTitle && "flex-direction: column;"}
    overflow-y: visible;
    gap: ${space.s05}
    position: relative;
  `;
}}
`;
const StyledModalDialogChildren = _styledcomponents2.default.call(void 0, _containerbox.ContainerBox).attrs((_ref2) => {
  let {
    theme: {
      space
    }
  } = _ref2;
  return {
    gap: space.s05,
    aligntItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    fullWidth: true
  };
})`
  ${(_ref3) => {
  let {
    theme: {
      space
    }
  } = _ref3;
  return `
    padding: 0px;
    flex-grow: 1;
    gap: ${space.s05}
  `;
}}
`;
const StyledDialogTitle = _styledcomponents2.default.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;
`;
const StyledDialog = _styledcomponents2.default.call(void 0, _dialog.Dialog)`
  ${(_ref4) => {
  let {
    $customMaxWidth
  } = _ref4;
  return $customMaxWidth && `max-width: ${$customMaxWidth};`;
}}
`;
const StyledPaperModal = _styledcomponents2.default.call(void 0, _foregroundpaper.ForegroundPaper)`
  ${(_ref5) => {
  let {
    theme: {
      palette,
      space
    }
  } = _ref5;
  return `
    background-color: ${_theme.colors[palette.mode].background.modals};
    padding: ${space.s07};
    gap: ${space.s05};
    margin: 0;
    position: relative;
  `;
}}
`;
const StyledCloseIconContainer = _styledcomponents2.default.div`
  display: flex;
`;
const StyledCloseIconButton = _styledcomponents2.default.call(void 0, _iconbutton.IconButton)`
  ${(_ref6) => {
  let {
    theme: {
      spacing
    }
  } = _ref6;
  return `
    position: absolute;
    top: ${spacing(4.5)};
    right: ${spacing(4.5)};
  `;
}}
`;
const Modal = (_ref7) => {
  let {
    title,
    open,
    onClose,
    maxWidth,
    customMaxWidth,
    showCloseIcon,
    showCloseButton,
    subtitle,
    actions,
    fullHeight,
    keepMounted,
    children,
    closeOnBackdrop,
    headerButton,
    actionsAlignment = "vertical",
    extraActions
  } = _ref7;
  const {
    palette: {
      mode
    },
    spacing
  } = _styledcomponents.useTheme.call(void 0, );
  const handleClose = () => {
    if (onClose && (showCloseButton || showCloseIcon || closeOnBackdrop)) {
      onClose();
    }
  };
  const withTitle = showCloseIcon || !!title;
  const fullHeightProps = fullHeight && {
    sx: {
      height: "90vh"
    }
  } || {};
  const titleComponent = _react.useMemo.call(void 0, () => !!title ? /* @__PURE__ */ _react2.default.createElement(StyledDialogTitle, null, /* @__PURE__ */ _react2.default.createElement(StyledDialogHeader, null, /* @__PURE__ */ _react2.default.createElement(_containerbox.ContainerBox, {
    flexDirection: "column",
    gap: 2
  }, /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "h3Bold",
    color: _theme.colors[mode].typography.typo1
  }, title), subtitle && /* @__PURE__ */ _react2.default.createElement(_typography.Typography, {
    variant: "bodyRegular",
    color: _theme.colors[mode].typography.typo2
  }, subtitle)), headerButton)) : null, [title, subtitle, headerButton, mode]);
  const closeIconComponent = _react.useMemo.call(void 0, () => showCloseIcon ? /* @__PURE__ */ _react2.default.createElement(StyledCloseIconContainer, null, /* @__PURE__ */ _react2.default.createElement(StyledCloseIconButton, {
    "aria-label": "close",
    onClick: onClose
  }, /* @__PURE__ */ _react2.default.createElement(_icons.CloseIcon, {
    sx: {
      color: _theme.colors[mode].typography.typo2
    },
    size: spacing(3)
  }))) : null, [showCloseIcon, onClose, mode, spacing]);
  return /* @__PURE__ */ _react2.default.createElement(StyledDialog, {
    open,
    fullWidth: true,
    maxWidth: maxWidth || "lg",
    onClose: handleClose,
    PaperProps: {
      ...fullHeightProps,
      style: {
        maxWidth: customMaxWidth
      }
    },
    keepMounted,
    PaperComponent: StyledPaperModal
  }, /* @__PURE__ */ _react2.default.createElement(StyledDialogContent, {
    withTitle: withTitle || !!fullHeight
  }, titleComponent, closeIconComponent, /* @__PURE__ */ _react2.default.createElement(StyledModalDialogChildren, null, children)), (showCloseButton || !!_optionalChain([actions, 'optionalAccess', _ => _.length])) && /* @__PURE__ */ _react2.default.createElement(_containerbox.ContainerBox, {
    flexDirection: actionsAlignment === "vertical" ? "column" : "row",
    gap: 4,
    "justify-content": "center",
    alignItems: "center"
  }, showCloseButton && actionsAlignment === "horizontal" && /* @__PURE__ */ _react2.default.createElement(_button.Button, {
    onClick: onClose,
    variant: "outlined",
    size: "large",
    sx: {
      width: "100%"
    }
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "wzEneQ",
    defaultMessage: [{
      "type": 0,
      "value": "Close"
    }]
  })), _optionalChain([actions, 'optionalAccess', _2 => _2.map, 'call', _3 => _3((action, index) => action.options ? /* @__PURE__ */ _react2.default.createElement(_splitbutton.SplitButton, {
    onClick: action.onClick,
    text: action.label,
    disabled: action.disabled,
    variant: _nullishCoalesce(action.variant, () => ( "outlined")),
    color: _nullishCoalesce(action.color, () => ( "primary")),
    options: action.options,
    size: "large",
    block: true,
    key: index
  }) : /* @__PURE__ */ _react2.default.createElement(_button.Button, {
    onClick: action.onClick,
    disabled: action.disabled,
    variant: _nullishCoalesce(action.variant, () => ( "outlined")),
    color: _nullishCoalesce(action.color, () => ( "primary")),
    size: "large",
    key: index,
    sx: {
      width: "100%"
    }
  }, action.label))]), showCloseButton && actionsAlignment === "vertical" && /* @__PURE__ */ _react2.default.createElement(_button.Button, {
    onClick: onClose,
    variant: "contained",
    size: "large",
    sx: {
      width: "100%"
    }
  }, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "wzEneQ",
    defaultMessage: [{
      "type": 0,
      "value": "Close"
    }]
  })), extraActions));
};


exports.Modal = Modal;
