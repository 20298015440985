"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function PersonOutlineRoundedIcon(_ref) {
  let {
    size,
    color,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 25 24"
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M13.0766 11.62C13.0466 11.62 13.0266 11.62 12.9966 11.62C12.9466 11.61 12.8766 11.61 12.8166 11.62C9.91656 11.53 7.72656 9.25 7.72656 6.44C7.72656 3.58 10.0566 1.25 12.9166 1.25C15.7766 1.25 18.1066 3.58 18.1066 6.44C18.0966 9.25 15.8966 11.53 13.1066 11.62C13.0966 11.62 13.0866 11.62 13.0766 11.62ZM12.9166 2.75C10.8866 2.75 9.22656 4.41 9.22656 6.44C9.22656 8.44 10.7866 10.05 12.7766 10.12C12.8266 10.11 12.9666 10.11 13.0966 10.12C15.0566 10.03 16.5966 8.42 16.6066 6.44C16.6066 4.41 14.9466 2.75 12.9166 2.75Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M13.0866 22.55C11.1266 22.55 9.1566 22.05 7.6666 21.05C6.2766 20.13 5.5166 18.87 5.5166 17.5C5.5166 16.13 6.2766 14.86 7.6666 13.93C10.6666 11.94 15.5266 11.94 18.5066 13.93C19.8866 14.85 20.6566 16.11 20.6566 17.48C20.6566 18.85 19.8966 20.12 18.5066 21.05C17.0066 22.05 15.0466 22.55 13.0866 22.55ZM8.4966 15.19C7.5366 15.83 7.0166 16.65 7.0166 17.51C7.0166 18.36 7.5466 19.18 8.4966 19.81C10.9866 21.48 15.1866 21.48 17.6766 19.81C18.6366 19.17 19.1566 18.35 19.1566 17.49C19.1566 16.64 18.6266 15.82 17.6766 15.19C15.1866 13.53 10.9866 13.53 8.4966 15.19Z"
  }));
}


exports.default = PersonOutlineRoundedIcon;
