"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function InformationIcon(_ref) {
  let {
    size,
    color,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 21 20"
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M10.2579 18.949C9.73294 18.949 9.21628 18.774 8.80794 18.424L7.49128 17.299C7.35794 17.1823 7.02461 17.0656 6.84961 17.0656H5.39128C4.15794 17.0656 3.15794 16.0656 3.15794 14.8323V13.4073C3.15794 13.2323 3.04128 12.899 2.93294 12.774L1.79961 11.4406C1.11628 10.6323 1.11628 9.36563 1.79961 8.54896L2.93294 7.21562C3.04128 7.09062 3.15794 6.75729 3.15794 6.58229V5.16562C3.15794 3.93229 4.15794 2.93229 5.39128 2.93229H6.83294C7.00794 2.93229 7.33294 2.80729 7.47461 2.69062L8.79128 1.56562C9.60794 0.873958 10.8829 0.873958 11.6996 1.56562L13.0163 2.69062C13.1496 2.80729 13.4913 2.92396 13.6663 2.92396H15.0829C16.3163 2.92396 17.3163 3.92396 17.3163 5.15729V6.57396C17.3163 6.74896 17.4413 7.07396 17.5579 7.21562L18.6829 8.53229C19.3829 9.35729 19.3746 10.6323 18.6829 11.4406L17.5579 12.7573C17.4413 12.899 17.3246 13.224 17.3246 13.399V14.8156C17.3246 16.049 16.3246 17.049 15.0913 17.049H13.6746C13.4996 17.049 13.1746 17.174 13.0246 17.2906L11.7079 18.4156C11.2996 18.774 10.7746 18.949 10.2579 18.949ZM5.39128 4.18229C4.84961 4.18229 4.40794 4.62396 4.40794 5.16562V6.58229C4.40794 7.05729 4.19128 7.65729 3.88294 8.02396L2.74961 9.35729C2.46628 9.69896 2.46628 10.299 2.74961 10.6323L3.87461 11.9573C4.17461 12.299 4.39961 12.924 4.39961 13.399V14.824C4.39961 15.3656 4.84128 15.8073 5.38294 15.8073H6.83294C7.29961 15.8073 7.91628 16.0323 8.28294 16.3406L9.60794 17.474C9.94961 17.7656 10.5496 17.7656 10.8913 17.474L12.2079 16.349C12.5829 16.0323 13.1913 15.8156 13.6579 15.8156H15.0746C15.6163 15.8156 16.0579 15.374 16.0579 14.8323V13.4156C16.0579 12.949 16.2829 12.3406 16.5913 11.9656L17.7246 10.6406C18.0163 10.299 18.0163 9.69896 17.7246 9.35729L16.5996 8.04062C16.2829 7.66562 16.0663 7.05729 16.0663 6.59062V5.16562C16.0663 4.62396 15.6246 4.18229 15.0829 4.18229H13.6663C13.1913 4.18229 12.5746 3.95729 12.2079 3.64896L10.8829 2.51562C10.5413 2.22396 9.94961 2.22396 9.59961 2.51562L8.29128 3.64896C7.91628 3.95729 7.30794 4.18229 6.83294 4.18229H5.39128Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M10.2493 14.0573C9.79102 14.0573 9.41602 13.6823 9.41602 13.224C9.41602 12.7656 9.78268 12.3906 10.2493 12.3906C10.7077 12.3906 11.0827 12.7656 11.0827 13.224C11.0827 13.6823 10.716 14.0573 10.2493 14.0573Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M10.25 11.4318C9.90833 11.4318 9.625 11.1484 9.625 10.8068V6.77344C9.625 6.43177 9.90833 6.14844 10.25 6.14844C10.5917 6.14844 10.875 6.43177 10.875 6.77344V10.7984C10.875 11.1484 10.6 11.4318 10.25 11.4318Z"
  }));
}


exports.default = InformationIcon;
