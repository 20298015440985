"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function ShieldTick(_ref) {
  let {
    size,
    color,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 25 24"
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M12.2504 22.7614C11.1604 22.7614 10.0804 22.4414 9.23039 21.8114L4.93039 18.6014C3.79039 17.7514 2.90039 15.9714 2.90039 14.5614V7.12141C2.90039 5.58141 4.03039 3.94141 5.48039 3.40141L10.4704 1.53141C11.4604 1.16141 13.0204 1.16141 14.0104 1.53141L19.0004 3.40141C20.4504 3.94141 21.5804 5.58141 21.5804 7.12141V14.5514C21.5804 15.9714 20.6904 17.7414 19.5504 18.5914L15.2504 21.8014C14.4204 22.4414 13.3404 22.7614 12.2504 22.7614ZM11.0004 2.94141L6.01039 4.81141C5.16039 5.13141 4.41039 6.21141 4.41039 7.13141V14.5614C4.41039 15.5114 5.08039 16.8414 5.83039 17.4014L10.1304 20.6114C11.2804 21.4714 13.2204 21.4714 14.3804 20.6114L18.6804 17.4014C19.4404 16.8314 20.1004 15.5114 20.1004 14.5614V7.12141C20.1004 6.21141 19.3504 5.13141 18.5004 4.80141L13.5104 2.93141C12.8304 2.69141 11.6704 2.69141 11.0004 2.94141Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M10.9102 14.2311C10.7202 14.2311 10.5302 14.1611 10.3802 14.0111L8.77023 12.4011C8.48023 12.1111 8.48023 11.6311 8.77023 11.3411C9.06023 11.0511 9.54023 11.0511 9.83023 11.3411L10.9102 12.4211L14.6802 8.65109C14.9702 8.36109 15.4502 8.36109 15.7402 8.65109C16.0302 8.94109 16.0302 9.42109 15.7402 9.71109L11.4402 14.0111C11.2902 14.1611 11.1002 14.2311 10.9102 14.2311Z"
  }));
}


exports.default = ShieldTick;
